import React, { useEffect, useRef, useState } from 'react';
import cx from 'classnames';
import useInfiniteScroll from 'react-infinite-scroll-hook';

import Loader from '~components/loader';
import CardSkeleton from '~components/card/skeleton';

import { isVerified } from '~helpers/common';

import NoResults from './no-results';
import ResultsItem from './results-item';

const SKELETON_COUNT = 6;

const ResultsList = ({ matches = [], count, isCityPage=false, cityName=false, citySlug=false, locationId, isLoading, onShowMore = () => {}, isLoadingMore }) => {
  const listRef = useRef(null);
  // Add a ref to track if we've ever had data
  const hasEverHadDataRef = useRef(matches && matches.length > 0);
  // Add state to store previous matches
  const [prevMatches, setPrevMatches] = useState(matches);
  
  const observerRef = useRef();
  const [sentryRef] = useInfiniteScroll({
    loading: isLoadingMore,
    hasNextPage: count > matches?.length,
    onLoadMore: onShowMore,
    // When there is an error, we stop infinite loading.
    // It can be reactivated by setting "error" state as undefined.
    disabled: false,
    // `rootMargin` is passed to `IntersectionObserver`.
    // We can use it to trigger 'onLoadMore' when the sentry comes near to become
    // visible, instead of becoming fully visible on the screen.
    rootMargin: '0px 0px 400px 0px',
    rootRef: observerRef,
  });

  // Reset state when matches become empty (city change)
  useEffect(() => {
    if (matches.length === 0) {
      setPrevMatches([]);
      hasEverHadDataRef.current = false;
    }
  }, [matches]);

  // Cleanup IntersectionObserver
  useEffect(() => {
    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    if (isLoading && listRef && listRef.current) {
      listRef.current.scrollTo(0, 0);
    }
  }, [isLoading]);

  // Update our tracking ref whenever matches has data
  useEffect(() => {
    if (matches && matches.length > 0) {
      hasEverHadDataRef.current = true;
      // Store current matches as previous matches when they're not empty
      setPrevMatches(matches);
    }
  }, [matches]);

  // Determine which matches to display
  const displayMatches = matches.length > 0 ? matches : (isLoading ? prevMatches : matches);
  
  // Check if we have matches to display, even if isLoading is true
  const hasServerRenderedData = displayMatches && displayMatches.length > 0;
  // Only show skeleton if we're loading, have no data to display, and have never had data before
  const shouldShowSkeleton = isLoading && !hasServerRenderedData && !hasEverHadDataRef.current;

  return (
    <div
      className={cx('c-matches-list c-matches-list--narrow', {
        'c-matches-list--no-results': displayMatches.length === 0,
      })}
    >
      <div className="c-matches-list__results" ref={listRef}>
        {shouldShowSkeleton ? (
          <CardSkeleton skeletonCount={SKELETON_COUNT} />
        ) : displayMatches && displayMatches.length > 0 ? (
          <>
            {displayMatches.map((match) => (
              <ResultsItem
                key={match.personId}
                locationId={locationId}
                personId={match.personId}
                score={match.score}
                price={match.price}
                address={match.location}
                experience={match.experience}
                firstName={match.firstName}
                profilePicture={match.profilePicture}
                verification={isVerified(match.verification)}
                lastLogin={match.lastLogin}
                isOnline={match.isOnline}
                personalNote={match.personalNote}
                registerDate={match.registerDate}
                days={match.days}
                published={match.published}
                isCityPage={isCityPage}
                cityName={cityName}
                citySlug={citySlug}
                showFavorite
              />
            ))}
            {(displayMatches.length < count || isLoadingMore) && (
              <div className="c-infinite-scroll" ref={sentryRef}>
                <Loader />
              </div>
            )}
          </>
        ) : (
          <NoResults />
        )}
      </div>
    </div>
  );
};

export default ResultsList;
