import React, { memo, useContext, useState, useCallback, useEffect, useRef } from 'react';

import WorkerCard from '~components/card/worker';
import ProfileModal from '~containers/profile/modal/worker';
import ServerContext from '~src/serverContext';
import WorkerCardMobile from '~components/card/worker/indexMobile';
// import { useIsMobile } from '~hooks/common';

const ResultsItem = memo(({
  personId,
  locationId,
  score,
  price,
  address,
  experience,
  firstName,
  profilePicture,
  verification,
  lastLogin,
  isOnline,
  registerDate,
  personalNote,
  days,
  published,
  showFavorite,
  isFavorite,
  isCityPage=false,
  cityName=false,
  citySlug=false,
  onFavorite = () => {},
  showCloseButton,
  onClose,
}) => {
  const [isProfileModalOpened, setIsProfileModalOpened] = useState(false);
  const modalMountedRef = useRef(false);

  // Cleanup effect when component unmounts
  useEffect(() => {
    modalMountedRef.current = true;
    return () => {
      modalMountedRef.current = false;
      // Ensure modal is closed on unmount
      setIsProfileModalOpened(false);
    };
  }, []);

  const handleModalToggle = useCallback((newState) => {
    if (modalMountedRef.current) {
      setIsProfileModalOpened(newState);
    }
  }, []);

  const cache = useContext(ServerContext);
  const isMobile = cache.get('isMobile');
  // console.log('ismobile ', isMobile)
  // const isMobile = useIsMobile();
  
  return (
    <>
      {isMobile ? (
        <WorkerCardMobile
          key={personId}
          personId={personId}
          locationId={locationId}
          score={score}
          price={price}
          address={address}
          experience={experience}
          firstName={firstName}
          profilePicture={profilePicture}
          verification={verification}
          lastLogin={lastLogin}
          isOnline={isOnline}
          personalNote={personalNote}
          days={days}
          published={published}
          registerDate={registerDate}
          showViewButton
          onView={() => handleModalToggle(true)}
          showFavorite={showFavorite}
          isFavorite={isFavorite}
          showCloseButton={showCloseButton}
          onClose={onClose}
        />
      ) : (
        <WorkerCard
          key={personId}
          personId={personId}
          locationId={locationId}
          score={score}
          price={price}
          address={address}
          experience={experience}
          firstName={firstName}
          profilePicture={profilePicture}
          verification={verification}
          lastLogin={lastLogin}
          isOnline={isOnline}
          personalNote={personalNote}
          days={days}
          published={published}
          isCityPage={isCityPage}
          cityName={cityName}
          citySlug={citySlug}
          registerDate={registerDate}
          showViewButton
          onView={() => handleModalToggle(true)}
          showFavorite={showFavorite}
          isFavorite={isFavorite}
          onFavorite={onFavorite}
          showCloseButton={showCloseButton}
          onClose={onClose}
        />
      )}
  
      {isProfileModalOpened && (
        <ProfileModal
          personId={personId}
          locationId={locationId}
          score={score}
          price={price}
          address={address}
          experience={experience}
          firstName={firstName}
          profilePicture={profilePicture}
          verification={verification}
          lastLogin={lastLogin}
          isOnline={isOnline}
          personalNote={personalNote}
          registerDate={registerDate}
          published={published}
          isOpened={isProfileModalOpened}
          onToggle={handleModalToggle}
          isFavorite={isFavorite}
          onFavorite={onFavorite}
        />
      )}
    </>
  );  
});

export default ResultsItem;
