import React, { useRef, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import queryString from 'query-string';
import { useIntl } from 'react-intl';

import { useSeo } from '~hooks/useSeo';
import { useLayout } from '~hooks/useLayout';
import { useLocationDetails } from '~hooks/useLocationDetails';

import LocationSearchBar from './location-search-bar';
import LocationContext from './locationContext';
import Header from './header';
import WorkersList from './list';

import './style.scss';

import { getValidatedQueryString } from '~helpers/queryUtils';

const Workers = () => {
  const intl = useIntl();
  const urlLocation = useLocation();
  const [locationId, setLocationId] = useState(null);
  const initialSearchLocationId = useRef(null);
  const [isInitialLoad, setIsInitialLoad] = useState(true);

  const { locationDetails, pricingDetails, isLoading, requestLocationByID, locationTouched } = useLocationDetails(
    locationId,
    [urlLocation.pathname]
  );

  useEffect(() => {
    try {
      const queryParams = queryString.parse(getValidatedQueryString(urlLocation.search)) || null;
      const parsedLocationId = queryParams?.locationId || null;
      setLocationId(parsedLocationId);
      initialSearchLocationId.current = parsedLocationId;
    } catch (err) {
      setLocationId(null);
    } finally {
      setIsInitialLoad(false);
    }
  }, [urlLocation.search]);

  useEffect(() => {
    if (locationId && !locationDetails && !isLoading) {
      requestLocationByID(locationId);
    }
  }, [locationId, locationDetails, isLoading]);

  useLayout({
    displayFooter: false,
  });

  useSeo(
    intl.formatMessage({
      id: 'containers.workers.seo.default.title',
    })
  );

  return (
    <LocationContext.Provider
      value={{
        locationDetails,
        pricingDetails,
        initialSearchLocationId: initialSearchLocationId.current,
        onChangeLocation: requestLocationByID,
        isLoadingLocation: isLoading,
        locationTouched,
      }}
    >
      <section className="c-page l-container c-page--matches">
        <Header />
        
        {!locationId ? (
          <>
            <div className="c-location-intro">
              {intl.formatMessage({
                id: 'containers.workers.intro',
                defaultMessage: 'Lets get started! Please write down your address',
              })}
            </div>
            <LocationSearchBar />
          </>
        ) : !locationDetails && !isLoading ? (
          // <LocationSearchBar />
          null
        ) : ( 
          <WorkersList />
        )}
      </section>
    </LocationContext.Provider>
  );
};

export default Workers;
