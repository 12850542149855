import { useState, useEffect, useContext, useRef } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { v4 as uuidv4 } from 'uuid';
import { useAuth0 } from '@auth0/auth0-react';
import { UserContext } from '~contexts/user';

import * as REST from '~services/rest';

export const useListing = ({
  initialResults = [],
  initialCount = null,
  itemsPerPage = 12,
  debounceTime = 500,
  matchRequestName,
  countRequestName,
  matchMockFileName,
  countMockFileName,
  onNoResults = () => {},
  parseResult = (r) => {
    return r;
  },
  onCleanup = () => {},
}) => {
  const { isLoading: isLoadingAuthentication } = useAuth0();
  const { isLoaded: isLoadedUser } = useContext(UserContext);

  const [results, setResults] = useState(initialResults);
  const [count, setCount] = useState(initialCount);
  const [offset, setOffset] = useState(0);

  const [pendingDataRequests, setPendingDataRequests] = useState([]);
  const [pendingCountRequests, setPendingCountRequests] = useState([]);
  const [isLoadingResults, setIsLoadingResults] = useState(true);
  const [isLoadingCount, setIsLoadingCount] = useState(true);
  const [isLoadingMore, setIsLoadingMore] = useState(false);

  const isMounted = useRef(true);
  
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  const fetchDataWithCriteria = (searchCriteria) => {
    const requestId = uuidv4();
    const request = REST.get({
      name: matchRequestName,
      params: {
        ...searchCriteria,
      },
    }).then((response) => {
      if (!isMounted.current) return;
      
      if (typeof response === 'undefined' || !response.data) {
        return;
      }

      const newResults = response.data.map((r) => parseResult(r));
      setResults(newResults);
      setPendingDataRequests([]);
    });

    pendingDataRequests.forEach((r) => r.request.cancel());
    setPendingDataRequests([{ request, id: requestId }]);
  };

  const fetchCountWithCriteria = (searchCriteria, redirectOnNoResults = false) => {
    const requestId = uuidv4();
    const request = REST.get({
      name: countRequestName,
      mockFileName: countMockFileName,
      params: {
        count: true,
        ...searchCriteria,
      },
    }).then((response) => {
      if (typeof response === 'undefined' || !response.data || !response.data[0]) {
        return;
      }

      setCount(response.data[0].count || 0);
      setPendingCountRequests([]);
      if (redirectOnNoResults && response.data[0].count === 0) {
        onNoResults();
      }
    });

    pendingCountRequests.forEach((r) => r.request.cancel());
    setPendingCountRequests([{ request, id: requestId }]);
  };

  const debouncedCriteria = useDebouncedCallback((crit) => fetchDataWithCriteria(crit), debounceTime, {
    leading: true,
  });
  const debouncedCount = useDebouncedCallback(
    (crit, redirectOnNoResults) => fetchCountWithCriteria(crit, redirectOnNoResults),
    debounceTime,
    { leading: true }
  );

  const updateListing = (newCriteria, redirectOnNoResults = false) => {
    debouncedCriteria(newCriteria);
    debouncedCount(newCriteria, redirectOnNoResults);
    setOffset(0);
  };

  const reorder = (newCriteria) => {
    debouncedCriteria(newCriteria);
    setOffset(0);
  };

  const showMore = (newCriteria) => {
    if (isLoadingMore || offset >= count) {
      return;
    }

    setIsLoadingMore(true);
    const newOffset = offset + itemsPerPage;
    const criteriaWithPagination = {
      ...newCriteria,
      'pagination-limit': itemsPerPage,
      'pagination-offset': newOffset,
    };

    REST.get({
      name: matchRequestName,
      mockFileName: matchMockFileName,
      params: {
        ...criteriaWithPagination,
      },
    }).then((response) => {
      if (!isMounted.current) return;
      
      if (typeof response === 'undefined' || !response.data || !response.data.length) {
        setIsLoadingMore(false);
        return;
      }
      const newResults = response.data.map((r) => parseResult(r));
      setResults([...results, ...newResults]);
      setOffset(newOffset);
      setIsLoadingMore(false);
    });
  };

  useEffect(() => {
    if (pendingDataRequests.length > 0) {
      setIsLoadingResults(true);
    } else {
      setIsLoadingResults(false);
    }
  }, [pendingDataRequests]);

  useEffect(() => {
    if (pendingCountRequests.length > 0) {
      setIsLoadingCount(true);
    } else {
      setIsLoadingCount(false);
    }
  }, [pendingCountRequests]);

  useEffect(() => {
    onCleanup();
  }, [onCleanup]);

  return {
    results,
    count,
    offset,
    isLoadingResults,
    isLoadingCount,
    isLoadingMore,
    updateListing,
    reorder,
    showMore,
  };
};
